import { Container } from 'swp-components/lib/components/bootstrap'
import Footer from 'swp-components/lib/components/footer'
import news from 'swp-components/lib/components/news'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import Newrelic from '@/components/newrelic-agent'
import style from '@/components/news-events/detail.style'
import { TagNewsGrid } from '@/components/news-grid'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import useHomePageDataLayer from '@/hooks/data-layers/use-home-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const CatNewsTag = ({ serverData }) => {
  let [
    seo,
    headerScript,
    brandHeaders,
    productServices,
    footerHeaders,
    chatShop,
    tagContent,
    tagAllNews,
    pageGroup,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let { handleMessage, createScopeProps } = useHomePageDataLayer()

  if (!tagContent) {
    return <NotFoundPage />
  }

  let curPage = pageGroup.filter(
    a => a.slug.toLowerCase() === serverData.slug.toLowerCase()
  )
  if (tagContent.length > 0) {
    tagContent.map(a => {
      a.link = {
        url: `${serverData.locale !== 'th' ? '/en' : ''}/news/${
          a.pageGroup.slug
        }/${a.slug}`,
        newTab: true,
      }
      a.startDate = a.updatedAt
    })
  }

  let allNewsLocale = tagAllNews[0].news.filter(
    a => a.locale === serverData.locale
  )

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo
          seo={tagAllNews[0].seo ? tagAllNews[0].seo : seo.mainSeo}
          script={headerScript.script}
        />
        <Header items={brandHeaders} services={productServices} />

        {tagContent.length > 0 && (
          <Container>
            <div className="mt-5" css={style.box}>
              <SectionHeading tag="h1">{'News'}</SectionHeading>
              <SectionHeading
                tag="h2"
                variant="underline"
                className="mt-1"
                level="2"
              >
                {'ข่าวสารประชาสัมพันธ์'}
              </SectionHeading>
            </div>
            <div className="my-3" css={style.box}>
              <h2 css={style.header}>
                {'แท็ก:'}{' '}
                <span css={style.slug(tagAllNews[0].slug.toLowerCase())}>
                  {curPage[0].name}
                </span>
              </h2>
              <TagNewsGrid
                article={tagContent}
                slug={serverData.slug}
                locale={serverData.locale}
                count={allNewsLocale}
              ></TagNewsGrid>
            </div>
          </Container>
        )}
        {tagContent.length === 0 && (
          <Container>
            <div className="mt-5" css={style.box}>
              <SectionHeading tag="h1">{'News'}</SectionHeading>
              <SectionHeading
                tag="h2"
                variant="underline"
                className="mt-1"
                level="2"
              >
                {'ข่าวสารประชาสัมพันธ์'}
              </SectionHeading>
            </div>
            <div className="my-3" css={style.box}>
              <h2 css={style.header}>
                {'แท็ก:'}{' '}
                <span css={style.slug(tagAllNews[0].slug.toLowerCase())}>
                  {curPage[0].name}
                </span>
              </h2>
              <h2 css={style.headerContent} className="my-5 py-5">
                {serverData.locale === 'th' ? 'ไม่พบข้อมูล' : 'No contents'}
              </h2>
            </div>
          </Container>
        )}

        <Footer className="pb-4" items={footerHeaders} />
        <ChatShop
          content={chatShop}
          mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
          mariConfig={mariConfig}
        />
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default CatNewsTag

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents([
    'seo',
    'header-script',
    'brand-headers',
    'product-services',
    'footer-headers',
    'chat-and-shop',
    `news-and-events-tags/${params.slug}/news?_locale=${pageContext.locale}`,
    `news-and-events-tags?slug=${params.slug}`,
    'page-groups',
    'mari-chat-and-shop-config',
  ])

  let slug = params.slug
  return {
    props: { contents, slug: slug, locale: pageContext.locale },
  }
}
