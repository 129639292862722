import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { breakpoints } from 'swp-components/lib/assets/config/style'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button, DataLayer } from 'swp-components/lib/components/common'
import { Card } from 'swp-components/lib/components/news'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import useNewsTipsDataLayer from '@/hooks/data-layers/news-tips/use-news-tips-page'
import useColumns from '@/hooks/use-columns'

import style from './style'

const MainNewsGrid = ({
  color,
  article,
  count = [],
  slug = '',
  path = '',
  locale = 'th',
  ...props
}) => {
  let [rows, setRows] = useState(1)
  let [art, setArt] = useState(article)
  let { columns } = useColumns([
    { value: breakpoints.sm, columns: 2 },
    { value: breakpoints.md, columns: 2 },
    { value: breakpoints.lg, columns: 3 },
  ])

  let centered = art.length < columns

  let highlightNews = art[0]
  // eslint-disable-next-line array-callback-return
  art.map((a, index) => {
    if (index !== 0) {
      a.highlightImage = a.image
    }
  })

  let { handleMessage } = useNewsTipsDataLayer()
  return (
    <DataLayerProvider handler={handleMessage}>
      <Container className="mb-5">
        {highlightNews != null && (
          <Row className="mb-5">
            <DataLayer
              selector={`NewsEvent.Card#${highlightNews.id}`}
              triggers={['click']}
              context={{ articles: highlightNews, path }}
              render={ref => (
                <Col ref={ref}>
                  <Card
                    className="my-3"
                    css={style.card}
                    variant="hero"
                    article={highlightNews}
                  />
                </Col>
              )}
            />
          </Row>
        )}
        <div css={style.wrapper(centered)} {...props}>
          {art.map(
            (articles, index) =>
              index !== 0 && (
                <DataLayer
                  selector={`NewsEvent.Card#${articles.id}`}
                  triggers={['click']}
                  context={{ articles, path }}
                  key={articles.id}
                  render={ref => (
                    <div
                      ref={ref}
                      css={style.box}
                      style={{ width: `${100 / columns}%` }}
                    >
                      <Card
                        css={style.card(true)}
                        color={color}
                        article={articles}
                        variant="elipsis"
                      />
                    </div>
                  )}
                />
              )
          )}
        </div>
        {(art.length === 7 || art.length === 6 * rows + 1) &&
          art.length < count.length && (
            <div className="d-flex justify-content-center my-5">
              <DataLayer
                selector={`NewsEvent.ViewAllButton`}
                triggers={['click']}
                context={{ path }}
                render={ref => (
                  <Button
                    ref={ref}
                    color="orange"
                    variant="outlined"
                    onClick={() => {
                      let nextArr = count.slice(rows * 6 + 1, rows * 6 + 7)
                      let arr = art.concat(nextArr)
                      setArt(arr)
                      setRows(rows + 1)
                    }}
                  >
                    <FormattedMessage
                      id="viewMore"
                      defaultMessage="View More"
                    />
                  </Button>
                )}
              />
            </div>
          )}
      </Container>
    </DataLayerProvider>
  )
}

export default MainNewsGrid
